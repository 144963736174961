import { createContext, useState } from 'react';

export const RefContext = createContext({
  addBlock: false,
  setAddBlock: () => {}, // Add a placeholder for the setter
});

export const RefProvider = ({ children }) => {
  const [addBlock, setAddBlock] = useState(false);
  return (
    <RefContext.Provider value={{ addBlock, setAddBlock }}>
      {children}
    </RefContext.Provider>
  );
};

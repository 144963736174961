import './App.scss';
import AppRoutes from './Routes/router';

function App() {
  return (
    <>
  <AppRoutes/>
    </>
  );
}

export default App;
